<template>
  <ez-content class="contacts-overview">
    <ez-container>
      <ez-facts :items="facts"
        v-if="facts.length > 0"
        :max="facts.length"
        :limit="facts.length"
        :fluid="true"
        />
      <ez-row>
        <ez-col>
          <ez-box>
            <template slot="header">
              {{ $t('overview.general-header') }}
            </template>
            <template slot="body">
              <ez-form>
                <ez-form-group>
                  <template slot="header">
                    {{ $t('overview.personal-info-header') }}
                  </template>
                  <ez-row justify="between" gutter="20" class="mt-2">
                    <ez-col>
                      <ez-form-item
                        :label="$t('overview.first-name-label')"
                        :type="errors.has('first-name') ? 'error': 'message'"
                        :message="errors.has('first-name') ? $t('overview.first-name-error'): ''"
                        >
                        <ez-input
                          :readonly="readonly"
                          v-model="formData.firstName"
                          name="first-name"
                          />
                      </ez-form-item>
                    </ez-col>
                    <ez-col>
                      <ez-form-item
                        :label="$t('overview.email-label')"
                        :type="errors.has('email') ? 'error': 'message'"
                        :message="
                           errors.has('email')
                           ? (errors.first('email') || $t('overview.email-error'))
                           : ''
                        "
                      >
                        <ez-input
                          :readonly="readonly"
                          v-model="formData.email"
                          v-validate="{ required: true, email: true }"
                          name="email"
                          />
                      </ez-form-item>
                    </ez-col>
                  </ez-row>
                </ez-form-group>

                <ez-form-group>
                  <ez-row justify="between" gutter="20">
                    <ez-col>
                      <ez-form-item
                        :label="$t('overview.last-name-label')"
                        :type="errors.has('last-name') ? 'error': 'message'"
                        :message="errors.has('last-name') ? $t('overview.last-name-error'): ''"
                        >
                        <ez-input
                          :readonly="readonly"
                          v-model="formData.lastName"
                          name="last-name"
                          />
                      </ez-form-item>
                    </ez-col>
                    <ez-col>
                      <ez-form-item
                        :label="$t('overview.phone-label')">
                        <ez-input
                          :readonly="readonly"
                          v-model="formData.phone"
                          />
                      </ez-form-item>
                    </ez-col>
                  </ez-row>
                </ez-form-group>

                <ez-form-group>
                  <template slot="header">
                    {{ $t('overview.address-header') }}
                  </template>
                  <ez-row gutter="20" justify="between" class="mt-2">
                    <ez-col>
                      <ez-form-item
                        :label="$t('overview.address-label')">
                        <ez-input :readonly="readonly" v-model="formData.addr"/>
                      </ez-form-item>
                    </ez-col>
                    <ez-col>
                      <ez-form-item
                        :label="$t('overview.city-label')">
                        <ez-input :readonly="readonly" v-model="formData.city"/>
                      </ez-form-item>
                    </ez-col>
                  </ez-row>
                </ez-form-group>

                <ez-form-group>
                  <ez-row gutter="20" justify="between">
                    <ez-col>
                      <ez-form-item
                        :label="$t('overview.zip-label')">
                        <ez-input :readonly="readonly" v-model="formData.zip"/>
                      </ez-form-item>
                    </ez-col>
                    <ez-col>
                      <ez-form-item
                        :label="$t('overview.country-label')">
                        <ez-input :readonly="readonly" v-model="formData.country"/>
                      </ez-form-item>
                    </ez-col>
                  </ez-row>
                </ez-form-group>

                <ez-form-group>
                  <ez-row gutter="20" justify="between">
                    <ez-col>
                      <ez-form-item
                        :label="$t('overview.state')">
                        <ez-input :readonly="readonly" v-model="formData.state"/>
                      </ez-form-item>
                    </ez-col>
                    <ez-col />
                  </ez-row>
                </ez-form-group>

                <div class="subheader"></div>

                <ez-form-group>
                  <template slot="header">
                    {{ $t('overview.other-info-header') }}
                  </template>
                  <ez-row gutter="20" justify="bwtween" class="mt-2" v-if="isCustomer">
                    <ez-col>
                      <ez-form-item
                        :label="$t('overview.customer-type-label')">
                        <ez-input :value="customerTypeDivisionLabel" :readonly="true"/>
                      </ez-form-item>
                    </ez-col>
                    <ez-col>
                      <ez-form-item
                        :label="$t('overview.shopping-behavior-label')">
                        <ez-input :value="customerShoppingBehaviorLabel" :readonly="true"/>
                      </ez-form-item>
                    </ez-col>
                  </ez-row>
                  <ez-row gutter="20" justify="bwtween" class="mt-2">
                    <ez-col>
                      <ez-form-item
                        :label="$t('overview.ip-label')">
                        <ez-input :value="formData.ip" :readonly="true"/>
                      </ez-form-item>
                    </ez-col>
                    <ez-col>
                      <ez-form-item
                        :label="$t('overview.optin-date-label')">
                        <ez-input :value="formData.optinDate | userTime" :readonly="true"/>
                      </ez-form-item>
                    </ez-col>
                  </ez-row>
                </ez-form-group>

                <ez-form-group>
                  <ez-row gutter="20">
                    <ez-col size="12">
                      <ez-form-item
                        :label="$t('overview.confirm-date-label')">
                        <ez-input :value="formData.confirmedAt | userTime" :readonly="true"/>
                      </ez-form-item>
                    </ez-col>
                  </ez-row>
                </ez-form-group>

                <ez-form-group>
                  <ez-row gutter="20" justify="between">
                    <ez-col>
                      <ez-form-item
                        :label="$t('overview.tags-label')">
                        <ez-select
                          v-model="tags"
                          :options.sync="tagsOptions"
                          :multiple="true"
                          :taggable="true"
                          :readonly="false"
                          track-by="id"
                          item-label="name"
                          @tag="onTag"
                        />
                      </ez-form-item>
                    </ez-col>
                    <ez-col>
                      <ez-form-item
                        :label="$t('overview.notes-label')">
                        <ez-textarea
                          size="150"
                          :readonly="readonly"
                          :rows="6"
                          resize="false"
                          :placeholder="$t('overview.notes-placeholder')"
                          v-model="formData.notes"
                        />
                      </ez-form-item>
                    </ez-col>
                  </ez-row>
                </ez-form-group>

                <!-- custom fields -->
                <ez-form-group v-if="!additionalFieldsKeysIsEmpty">
                  <template slot="header">
                    {{ $t('overview.additional-fields') }}
                  </template>
                  <ez-row
                    v-for="(addKeysChunk, index) in additionalFieldsKeys"
                    gutter="20"
                    justify="between"
                    class="mt-2"
                    :key="`add-fields-chunk-${index}`"
                    >
                    <ez-col
                      v-for="(addField, fieldIndex) in addKeysChunk"
                      :key="`add-fields-item-${index}-${fieldIndex}`"
                      >
                      <ez-form-item
                        :label="addField.type !== 'boolean' ? addField.name : ''">
                        <ez-textarea
                          v-if="addField.type === 'string'"
                          v-model="currentContact['additional-fields'][addField.name]" />
                        <ez-input
                          v-if="addField.type === 'number'"
                          :type="addField.type"
                          :value="currentContact['additional-fields'][addField.name]"
                          @input="currentContact['additional-fields'][addField.name]
                          = isNaN(parseFloat($event)) ? 0 : parseFloat($event)"
                          />
                        <ez-checkbox
                          v-if="addField.type === 'boolean'"
                          :label="addField.name"
                          v-model="currentContact['additional-fields'][addField.name]"
                          />
                      </ez-form-item>
                    </ez-col>
                  </ez-row>
                </ez-form-group>
              </ez-form>

              <ez-button-group justify="end" v-if="false && errors.items.length > 0">
                <ez-notification
                  class="mt-3 mb-3 align-right"
                  status="warning"
                  type="horizontal"
                  :isCloseable="false">
                  <template slot="title">
                    <span>Please complete form</span>
                  </template>
                </ez-notification>
              </ez-button-group>

              <ez-button-group justify="end" class="mt-3">
                <ez-button
                  @click="preDiscardFormUpdate"
                  :disabled="disabled"
                  type="secondary"
                >
                {{ $t('overview.discard-button') }}
                </ez-button>
                <ez-button
                  @click="preSave"
                  :disabled="disabled"
                  :preloader="updating"
                  type="primary"
                >
                {{ $t('overview.save-button') }}
                </ez-button>
              </ez-button-group>
            </template>
          </ez-box>
        </ez-col>
      </ez-row>
    </ez-container>
  </ez-content>
</template>

<script>
import difference from 'lodash/difference';
import get from 'lodash/get';
import isBoolean from 'lodash/isBoolean';
import isNumber from 'lodash/isNumber';
import isEmpty from 'lodash/isEmpty';
import chunk from 'lodash/chunk';
import * as contactsGetters from '@/store/contacts/getters';
import * as contactsActions from '@/store/contacts/actions';
import * as tagsGetters from '@/store/tags/getters';
import * as tagsActions from '@/store/tags/actions';
import * as authGetters from '@/store/auth/getters';
import * as injectNames from '@/services/names';

import contactMixin from '@/mixins/contact';

import iconLead from '@/assets/icons/lead.svg';
import iconGlasses from '@/assets/icons/glasses.svg';
import iconWallet from '@/assets/icons/wallet.svg';
import iconWebinarUser from '@/assets/icons/webinar-user.svg';
import { types as contactTypes } from '@/services/formatters/contactType';
import userTime from '@/services/formatters/userTime';

export default {
  name: 'AppOverview',
  mixins: [contactMixin],
  dependencies: [injectNames.TAGS_API, '$log'],
  filters: {
    userTime,
  },
  data() {
    return {
      submited: false,
      addTag: false,
      readonly: false,
      tags: [],
      // tagsOptions: ["Tag1", "Tag2", "Tag3"],
      formData: {},
      pageTitle: this.$t('header.menuLinks.overview'),
    };
  },
  created() {
    // init form data for current contact (if exists already)
    this.initFormData();
    // load tags from api
    this.loadTags();
  },
  watch: {
    // monitor contact load complete and init form data after that
    currentContact() {
      this.initFormData();
    },
    tagsOptions(newValue, oldValue) {
      const prevValueIsEmpty = !oldValue || oldValue.length === 0;
      if (newValue && prevValueIsEmpty) {
        this.initFormTags();
      }
    },
  },
  computed: {
    // current user
    currentUserId() {
      const user = this.$store.getters[`auth/${authGetters.CURRENT_USER}`];
      return user ? user.legacyId : undefined;
    },
    // tags from storage
    tagsOptions() {
      const items = this.$store.getters[`tags/${tagsGetters.ITEMS}`];
      return items;
    },
    tagsOptionsIds() {
      return this.tagsOptions ? this.tagsOptions.map((item) => item.id) : [];
    },
    tagsIds() {
      return this.tags ? this.tags.map((item) => item.id) : [];
    },
    enabled() {
      return this.currentContact != null;
    },
    disabled() {
      return !this.enabled || this.updating || this.errors.items.length > 0;
    },
    loading() {
      return this.$store.getters[`contacts/${contactsGetters.LOADING}`];
    },
    updating() {
      return this.$store.getters[`contacts/${contactsGetters.UPDATING}`];
    },
    isCustomer() {
      return (
        this.currentContact != null
        && this.currentContact.type === contactTypes.CUSTOMER
      );
    },
    customerTypeDivision() {
      return this.currentContact && this.currentContact.customerTypeDivision
        ? this.currentContact.customerTypeDivision
        : null;
    },
    customerTypeDivisionLabel() {
      return this.customerTypeDivision && this.customerTypeDivision.attributes
        ? this.customerTypeDivision.attributes.name
        : null;
    },
    customerShoppingBehaviorLabel() {
      return this.currentContact
        && this.currentContact['shopping-behavior'] != null
        ? `${this.currentContact['shopping-behavior']} days`
        : null;
    },
    facts() {
      if (this.currentContact == null) {
        return [];
      }
      return [
        {
          id: 1,
          header: get(this.currentContact, 'attributes.source', 'N/A'),
          icon: iconLead,
          text: this.$t('overview.widgets.source'),
        },
        {
          id: 2,
          header: get(this.currentContact, 'attributes.page-visits', 'N/A'),
          icon: iconGlasses,
          text: this.$t('overview.widgets.page-visits'),
        },
        {
          id: 3,
          header: get(this.currentContact, 'attributes.webinars-attended', 'N/A'),
          icon: iconWebinarUser,
          text: this.$t('webinars.widgets.attended-webinars'),
        },
        {
          id: 4,
          header: get(this.currentContact, 'attributes.revenue', 'N/A'),
          icon: iconWallet,
          text: this.$t('overview.widgets.revenue'),
        },
      ];
    },
    additionalFieldsKeys() {
      const data = get(this.currentContact, 'additional-fields', {});
      return data && chunk(
        Object.keys(data).map((name) => {
          const value = data[name];
          return {
            name,
            type: (isNumber(value) && 'number')
              || (isBoolean(value) && 'boolean')
              || 'string',
          };
        }),
        2,
      );
    },
    additionalFieldsKeysIsEmpty() {
      return isEmpty(this.additionalFieldsKeys);
    },
  },
  methods: {
    // init form data from state current user info
    initFormData() {
      const {
        firstName = '',
        lastName = '',
        email = '',
        ip = '',
        optinDate = '',
        dtCreate = '',
        confirmedAt = '',
        notes = '',
        country = '',
        city = '',
        addr = '',
        state = '',
        zip = '',
        phone = '',
      } = this.currentContact || {};

      this.formData = {
        firstName,
        lastName,
        email,
        ip,
        optinDate,
        dtCreate,
        notes,
        country,
        city,
        addr,
        state,
        zip,
        phone,
        confirmedAt,
      };

      this.initFormTags();
    },
    initFormTags() {
      if (this.currentContact && this.currentContact.tags && this.tagsOptions) {
        const tags = [];
        this.currentContact.tags.forEach((tagId) => {
          const tagItem = this.tagsOptions.find((item) => item.id === tagId);
          if (tagItem) { tags.push(tagItem); }
        });
        this.tags = tags;
      } else {
        this.tags = [];
      }
    },

    // tags actions
    loadTags() {
      this.$store.dispatch(`tags/${tagsActions.GET_ITEMS}`);
    },
    onTag(tag) {
      const api = this[injectNames.TAGS_API];
      const data = {
        name: tag,
        user: this.currentUserId,
      };
      api
        .createTag(data)
        .then((item) => {
          // append created tag item to tags store
          this.$store.dispatch(`tags/${tagsActions.APPEND_ITEM}`, item);
          // apend item to tags values
          this.tags.push(item);
        })
        .catch((err) => {
          this.$log({ err });
        });
    },

    // save / discard updates handlers
    preSave() {
      this.save();
    },
    async save() {
      const tagsToAttach = difference(this.tagsIds, this.currentContact.tags);
      const tagsToDetach = difference(this.currentContact.tags, this.tagsIds);
      const additionalFields = get(this.currentContact, 'additional-fields', null);

      const item = {
        id: this.currentContact.id,
        ...this.formData,
        ...(additionalFields && { 'additional-fields': additionalFields }),
        ip: undefined,
        optinDate: undefined,
        confirmedAt: undefined,
        dtCreate: undefined,
      };

      try {
        await this.$store.dispatch(`contacts/${contactsActions.UPDATE_ITEM}`, {
          item,
          setAsCurrent: true,
          tagsToAttach,
          tagsToDetach,
        });
      } catch (e) {
        // this.$notify({
        //   data: {
        //     type: 'error',
        //     content: e.message || e,
        //   },
        // });
        // show /data/attribute/* errors
        // eslint-disable-next-line no-restricted-syntax
        for (const error of e?.response?.data?.errors || []) {
          // eslint-disable-line no-unused-vars
          const { pointer } = error.source;
          const PREFIX = '/data/attribute/';
          const msg = error.detail || error.title;
          if (pointer.startsWith(PREFIX)) {
            const field = pointer.substr(PREFIX.length);
            console.log(field, msg);

            this.errors.add({
              field,
              msg,
            });

            this.$notify({
              data: {
                type: 'error',
                content: `${field}: ${msg}`,
              },
            });
          } else {
            this.$notify({
              data: {
                type: 'error',
                content: `${pointer}: ${msg}`,
              },
            });
          }
        }
      }
    },
    preDiscardFormUpdate() {
      this.discardFormUpdate();
    },
    discardFormUpdate() {
      this.initFormData();
    },
  },
};
</script>
